import { useQuery } from '@tanstack/vue-query'
import type { MaybeRef } from '~/types/helpers'
import type { ILeaseDocumentFieldGroup, ILeaseRegisterLease } from '@register'

export function getLeaseDocumentKey(
  companyId: MaybeRef<string | undefined>,
  leaseId: MaybeRef<string | undefined>,
) {
  return ['getLeaseDocumentId', companyId, leaseId]
}

export const useLeaseDocument = (
  companyId: MaybeRef<string | undefined>,
  leaseId: MaybeRef<string | undefined>,
  options: { filterGroups?: boolean } = {},
) => {
  const { filterGroups = false } = options
  return useQuery<ILeaseRegisterLease>({
    queryKey: getLeaseDocumentKey(companyId, leaseId),
    queryFn: ({ signal }) =>
      fetchAuthorized<ILeaseRegisterLease>(
        `/companies/${unref(companyId)!}/leaseRegister/leases/${unref(
          leaseId,
        )!}`,
        { signal },
      ).then((lease) => {
        if (filterGroups) return filterLeaseGroups(lease)
        return lease
      }),
    enabled: computed(() => !!unref(companyId) && !!unref(leaseId)),
    gcTime: 0,
    staleTime: 0,
    meta: {
      cache: false,
    },
  })

  function filterLeaseGroups(lease: ILeaseRegisterLease): ILeaseRegisterLease {
    const filteredGroups: ILeaseDocumentFieldGroup[] | undefined = lease.groups
      ?.filter((group) =>
        (group.sections ?? []).some(
          (section) => (section.fields ?? []).length > 0,
        ),
      )
      .map((group) => ({
        ...group,
        sections: (group.sections ?? []).filter(
          (section) => (section.fields ?? []).length > 0,
        ),
      }))
    return {
      ...lease,
      groups: filteredGroups,
    }
  }
}
